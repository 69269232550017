.settings-page{
    height:100%;
    display:flex;
    flex-direction: column;
}

.settings-page .list-group-item{
    display: flex;
}

.settings-header{
    display: flex;
}

.settings-page > .main{
    display: flex;
    flex-direction: column;
    height: 100%;
}

.settings-footer{
    display:flex;
    justify-content: flex-end;
    margin-bottom: 2rem;
}

.settings-feed-url{
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
}

/*.wrapper {*/
/*    display: flex;*/
/*    flex-flow: row wrap;*/
/*}*/

/*!* We tell all items to be 100% width, via flex-basis *!*/
/*.wrapper > * {*/
/*    flex: 1 100%;*/
/*}*/

/*!* We rely on source order for mobile-first approach*/
/* * in this case:*/
/* * 1. header*/
/* * 2. article*/
/* * 3. aside 1*/
/* * 4. aside 2*/
/* * 5. footer*/
/* *!*/

/*!* Medium screens *!*/
/*@media all and (min-width: 600px) {*/
/*    !* We tell both sidebars to share a row *!*/
/*    .aside { flex: 1 auto; }*/
/*}*/

/*!* Large screens *!*/
/*@media all and (min-width: 800px) {*/
/*    !* We invert order of first sidebar and main*/
/*     * And tell the main element to take twice as much width as the other two sidebars*/
/*     *!*/
/*    .main { flex: 2 0px; }*/
/*    .aside-1 { order: 1; }*/
/*    .main    { order: 2; }*/
/*    .aside-2 { order: 3; }*/
/*    .footer  { order: 4; }*/
/*}*/