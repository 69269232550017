.loading-icon{
    margin-left:auto;
    margin-right:auto;
    animation: loading-icon-spin infinite 1s linear;
}

/*.flex-loading-icon{*/
/*    display: flex;*/
/*    justify-self: center;*/
/*    align-self: center;*/
/*}*/

@keyframes loading-icon-spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
}