html, body, #root, #root > .container{
  height:100%;
}

body{
  min-height: 0;
}

.article-list{
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.read-article{
  position: relative;
}

.avatar{
  display:inline-block;
  font-size:1em;
  width:2.5em;
  height:2.5em;
  line-height:2.5em;
  text-align:center;
  border-radius:50%;
  vertical-align:middle;
  color:white;
}

.hackernews-avatar{
  background-color: orangered;
}

.googlenews-avatar{
  background-color: dodgerblue;
}

.keyword-avatar{
  background-color: darkmagenta;
}

.search-highlight{
  background: Highlight;
  color: HighlightText;
  font-weight: bold;
  text-decoration: underline;
}

.tag-cloud-toggle{
  cursor: pointer;
}

.unfluff-content{
  padding: .5rem;
}

.unfluff-text{
  text-wrap: normal;
  white-space: pre-wrap;
  font-family: "Trebuchet MS";
  font-size: 1rem;
}

.article-image{
  max-width: 100%;
  margin-bottom: 1rem;
}

/*.article-list:last-child .section-separator {*/
/*  display: none;*/
/*}*/

/*.article-list:not(:last-child) .section-separator{*/
.article-list .section-separator{
  /*background-image: url(data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9JzMwMHB4JyB3aWR0aD0nMzAwcHgnICBmaWxsPSIjMDAwMDAwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMzAgMzAiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xNTAgLTMyMCkiPjxnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxwb2x5Z29uIHBvaW50cz0iMTU0LjY2NSwzMjIgMTUyLDMyNC42NjUgMTUyLDMyNi4wOCAxNTYuMDgsMzIyICAgIj48L3BvbHlnb24+PHBvbHlnb24gcG9pbnRzPSIxNjAuMzIyLDMyMiAxNTIsMzMwLjMyMiAxNTIsMzMxLjczNyAxNjEuNzM3LDMyMiAgICI+PC9wb2x5Z29uPjxwb2x5Z29uIHBvaW50cz0iMTY1Ljk3OSwzMjIgMTUyLDMzNS45NzkgMTUyLDMzNy4zOTMgMTY3LjM5MywzMjIgICAiPjwvcG9seWdvbj48cG9seWdvbiBwb2ludHM9IjE3MS42MzYsMzIyIDE1MiwzNDEuNjM2IDE1MiwzNDMuMDUgMTczLjA1LDMyMiAgICI+PC9wb2x5Z29uPjxwb2x5Z29uIHBvaW50cz0iMTUyLDM0OCAxNTIuNzA3LDM0OCAxNzgsMzIyLjcwNyAxNzgsMzIyIDE3Ny4yOTMsMzIyIDE1MiwzNDcuMjkzICAgIj48L3BvbHlnb24+PHBvbHlnb24gcG9pbnRzPSIxNTguMzY0LDM0OCAxNzgsMzI4LjM2NCAxNzgsMzI2Ljk1IDE1Ni45NSwzNDggICAiPjwvcG9seWdvbj48cG9seWdvbiBwb2ludHM9IjE2NC4wMjEsMzQ4IDE3OCwzMzQuMDIxIDE3OCwzMzIuNjA3IDE2Mi42MDcsMzQ4ICAgIj48L3BvbHlnb24+PHBvbHlnb24gcG9pbnRzPSIxNjkuNjc4LDM0OCAxNzgsMzM5LjY3OCAxNzgsMzM4LjI2MyAxNjguMjYzLDM0OCAgICI+PC9wb2x5Z29uPjxwb2x5Z29uIHBvaW50cz0iMTc1LjMzNCwzNDggMTc4LDM0NS4zMzUgMTc4LDM0My45MiAxNzMuOTIsMzQ4ICAgIj48L3BvbHlnb24+PC9nPjwvZz48L3N2Zz4=);*/
  /*background-image: url(data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9JzMwMHB4JyB3aWR0aD0nMzAwcHgnICBmaWxsPSIjMDAwMDAwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMTAwIDEwMCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBhdGggZD0iTTk2Ljg3NSwyLjEyNUg1MEgzLjEyNWMtMC41NTIsMC0xLDAuNDQ4LTEsMVY1MHY0Ni44NzVjMCwwLjU1MywwLjQ0OCwxLDEsMUg1MGg0Ni44NzVjMC41NTMsMCwxLTAuNDQ3LDEtMVY1MFYzLjEyNSAgQzk3Ljg3NSwyLjU3Myw5Ny40MjgsMi4xMjUsOTYuODc1LDIuMTI1eiBNNTEsNDlWMjcuNTYzaDQ0Ljg3NVY0OUg1MXogTTQuMTI1LDUxSDQ5djIxLjQzOEg0LjEyNVY1MXogTTk1Ljg3NSwyNS41NjNINTFWNC4xMjUgIGg0NC44NzVWMjUuNTYzeiBNNDksNDlIMjcuNTYzVjQuMTI1SDQ5VjQ5eiBNNC4xMjUsNC4xMjVoMjEuNDM4VjQ5SDQuMTI1VjQuMTI1eiBNNC4xMjUsNzQuNDM4SDQ5djIxLjQzOEg0LjEyNVY3NC40Mzh6IE01MSw1MSAgaDIxLjQzOHY0NC44NzVINTFWNTF6IE05NS44NzUsOTUuODc1SDc0LjQzOFY1MWgyMS40MzhWOTUuODc1eiI+PC9wYXRoPjwvc3ZnPg==);*/
  /*background-image: url(data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9JzMwMHB4JyB3aWR0aD0nMzAwcHgnICBmaWxsPSIjMDAwMDAwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMjkgMzAiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKC00NTAgLTgwMCkiPjxnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxwYXRoIGQ9Ik00NTIsODAzdjdoM3YyaC0zdjdoM3YyaC0zdjdoN3YtM2gydjNoN3YtM2gydjNoN3YtN2gtM3YtMmgzdi03aC0zdi0yaDN2LTdoLTd2M2gtMnYtM2gtN3YzaC0ydi0zSDQ1MnogTTQ1Myw4MDl2LTVoNXY1ICAgIEg0NTN6IE00NTMsODE4di01aDV2NUg0NTN6IE00NTgsODI3aC01di01aDVWODI3eiBNNDYxLDgyNGgtMnYtM2gtM3YtMmgzdi0zaDJ2M2gzdjJoLTNWODI0eiBNNDYyLDgxOHYtNWg1djVINDYyeiBNNDY3LDgyN2gtNSAgICB2LTVoNVY4Mjd6IE00NzYsODIydjVoLTV2LTVINDc2eiBNNDczLDgyMWgtM3YzaC0ydi0zaC0zdi0yaDN2LTNoMnYzaDNWODIxeiBNNDc2LDgxM3Y1aC01di01SDQ3NnogTTQ3MSw4MDRoNXY1aC01VjgwNHogICAgIE00NjgsODA3aDJ2M2gzdjJoLTN2M2gtMnYtM2gtM3YtMmgzVjgwN3ogTTQ2Miw4MDRoNXY1aC01VjgwNHogTTQ2MSw4MDd2M2gzdjJoLTN2M2gtMnYtM2gtM3YtMmgzdi0zSDQ2MXoiPjwvcGF0aD48L2c+PC9nPjwvc3ZnPg==);*/
  background-image: url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9JzMwMHB4JyB3aWR0aD0nMzAwcHgnICBmaWxsPSIjMDAwMDAwIiB4bWxuczpkYz0iaHR0cDovL3B1cmwub3JnL2RjL2VsZW1lbnRzLzEuMS8iIHhtbG5zOmNjPSJodHRwOi8vY3JlYXRpdmVjb21tb25zLm9yZy9ucyMiIHhtbG5zOnJkZj0iaHR0cDovL3d3dy53My5vcmcvMTk5OS8wMi8yMi1yZGYtc3ludGF4LW5zIyIgeG1sbnM6c3ZnPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczpzb2RpcG9kaT0iaHR0cDovL3NvZGlwb2RpLnNvdXJjZWZvcmdlLm5ldC9EVEQvc29kaXBvZGktMC5kdGQiIHhtbG5zOmlua3NjYXBlPSJodHRwOi8vd3d3Lmlua3NjYXBlLm9yZy9uYW1lc3BhY2VzL2lua3NjYXBlIiB2aWV3Qm94PSIwIDAgODQ0LjM1NTQ3IDg0My43NDk5OSIgdmVyc2lvbj0iMS4xIiB4PSIwcHgiIHk9IjBweCI+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNDIyNS43MjIyLC03ODEuNzYwMTEpIj48cGF0aCBzdHlsZT0iIiBkPSJtIC00MjEwLjEyNTcsMTYwOS44NzYxIC0xNS41OTY1LC0xNS42MzQxIDQwNi4yMzk1LC00MDYuMjQwOSBjIDIyMy40MzE4LC0yMjMuNDMyNTkgNDA2LjgxMTIsLTQwNi4yNDA5OSA0MDcuNTA5OCwtNDA2LjI0MDk5IDEuNjMyNiwwIDMwLjYwNjIsMjkuMDA5OCAzMC42MDYyLDMwLjY0NDUgMCwxLjIyNjMgLTgxMS4yOTQxLDgxMy4xMDU0OSAtODEyLjUxOTUsODEzLjEwNTQ5IC0wLjM1MzcsMCAtNy42NjE0LC03LjAzNTMgLTE2LjIzOTUsLTE1LjYzNCB6IiBmaWxsPSIjMDAwMDAwIj48L3BhdGg+PC9nPjwvc3ZnPg==");
  background-repeat: repeat;
  background-size: .5rem;
  text-align: center;
  font-size: 1rem;
  height: .75rem;
  content: "";
  filter: invert(100%);
  display: flex;
}