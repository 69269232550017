body {
  margin: 0;
  padding: 0;
  font-family: "Trebuchet MS";
  overflow-x: hidden;
  min-height: 75rem;
  padding-top: 4.5rem;
}

.nav-shadow{
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5) !important
}

.container{
  overflow-x: hidden;
}

.card-header{
  /*background-color: transparent;*/
}

.card-header > button{
  height:100%;
  display: block;
  /*text-orientation: upright;*/
  /*writing-mode: vertical-lr;*/
  line-height: 100%;
  max-width: 1.75rem;
  column-width: fit-content;
}

.feed-item{
  margin-bottom:2px;
  height:100%;
  width:100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex: 1;
}

.feed-item .item-well{
  margin-bottom: 3px;
}

.feed-item img{
  /*max-width:64px;*/
}

.popular-feeds button{
  margin-right: 10px;
}

.popular-feeds li{
  text-transform: capitalize;
}

.feed-configuration{
  display: flex;
}

.feed-configuration > div{
  flex-direction: column;
  padding: 10px;
}

.btn.unsubscribe{
  margin-left:10px;
}

.feed-header{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.feed-header-item{

}

.loading-icon{
  margin-left:10px;
  animation: loading-icon-spin infinite 1s linear;
}

@keyframes loading-icon-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

/*.feed-item table{*/
  /*display: flex;*/
/*}*/

/*.feed-item table > tr{*/
  /*display: flex;*/
  /*flex-direction: row;*/
/*}*/

/*.feed-item table > tr > td{*/
  /*display: flex;*/
  /*flex-direction: column;*/
  /*align-items: flex-start;*/
/*}*/

/*.feed-item td:first-of-type{*/

/*}*/

/*.feed-item td:last-of-type {*/
  /*flex-grow: 1;*/
/*}*/

/*.feed-item ol{*/
  /*margin-left:15px !important;*/
  /*display:flex;*/
  /*flex-direction: column;*/
/*}*/

/*.feed-item ol > strong {*/
  /*display: flex;*/
  /*flex-direction: row;*/
/*}*/